import React from 'react';
import { INLINES, BLOCKS } from '@contentful/rich-text-types'

const hyperlink = {
    [INLINES.ASSET_HYPERLINK]: (node) => {
      const { file, title } = node.data.target.fields;
      const fileInfo = file['en-US'];
      const fileTitle = title['en-US'];
      return <a
      href={fileInfo.url} title={fileTitle}>{fileTitle}</a>
    }
  }

  const asset = {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        return <img width='100%' alt={title} title={title} description={description} src={file['en-US'].url} />
      }
  };


const options = (updatedOptions) => ({
  renderNode: {
      ...asset,
      ...hyperlink,
      ...updatedOptions,
    },
});

export default options;